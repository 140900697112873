.Landing__Wrapper {
  position: relative;
  z-index: 100;
  width: 90%;
  margin: 0 auto;

  a {
    color: primary;
    text-decoration: none;

    &:link {
      color: $primary;
    }

    &:visited {
      color: $primary;
    }

    &:hover {
      color: $secondary;
    }
  }
}

.Landing__Header {
  h1 {
    margin-top: 16px;
    margin-bottom: 6px;
  }

  h3 {
    margin-top: 0;
  }
}

.Landing__Section {
  @media screen and (min-width: 1000px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 1000px) {
    margin-top: 10%;
  }
}
