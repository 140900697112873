@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto Slab", serif;
  overflow: hidden;
  background: #dfe7f2; }

.Landing__Wrapper {
  position: relative;
  z-index: 100;
  width: 90%;
  margin: 0 auto; }
  .Landing__Wrapper a {
    color: primary;
    text-decoration: none; }
    .Landing__Wrapper a:link {
      color: #034aa6; }
    .Landing__Wrapper a:visited {
      color: #034aa6; }
    .Landing__Wrapper a:hover {
      color: #444359; }

.Landing__Header h1 {
  margin-top: 16px;
  margin-bottom: 6px; }

.Landing__Header h3 {
  margin-top: 0; }

@media screen and (min-width: 1000px) {
  .Landing__Section {
    margin-top: 90px; } }

@media screen and (max-width: 1000px) {
  .Landing__Section {
    margin-top: 10%; } }

.Face__Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100; }

